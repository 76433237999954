.root {
  position: fixed;
  width: 300px;
  height: 150px;
  right: 5px;
  bottom: 5px;
  background-color: var(--background-color);
  border: 1px solid #ccc;
  border-radius: .3rem;
  padding: 2rem 3rem;
  color: var(--font-color);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 30;
  animation: shrinkToTheCorner 2s 3s ease forwards;
}

.message {
  animation: opacity0 1s 2s ease forwards;
}

.label {
  font-size: 1rem;
  /* opacity: 0; */
  animation: opacity1 2s 3s ease forwards;
}

.file {
  font-size: 1rem;
  /* opacity: 0; */
  animation: opacity1 2s 3s ease forwards;
}

.barContainer {
  height: 5px;
  width: 100px;
  background: #0000;
  border: 1px solid var(--primary-color);
  overflow: hidden;
  margin-top: 5px;
  margin-bottom: 5px;
  /* opacity: 0; */
  animation: opacity1 2s 3s ease forwards;
}

.bar {
  height: inherit;
  background: var(--primary-color);
  transition: all 0.4s ease;
}
/* 
@keyframes shrinkToTheCorner {
  0% {
    
  } 100% {
    width: 300px;
    height: 150px;
    right: 5px;
    bottom: 5px;
  }
}

@keyframes opacity0 {
  0% {
    opacity: 1;
  } 40% {
    opacity: 0;
  } 100% {
    opacity: 0;
    height: 0;
    width: 0;
  }
}

@keyframes opacity1 {
  0% {
    opacity: 0;
  } 100% {
    opacity: 1;
  }
} */