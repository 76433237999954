.root {
  position: fixed;
  background-color: #0000;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
}
