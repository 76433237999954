.wrapper {
    position: relative;
    height: 45vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
    margin: auto;
  }

.boxWrapper {
  box-shadow: 5px 5px 20px #0003;
  padding: 1rem;
  border-radius: .2rem;
  border: 1px solid #0003
}
  
  .slideLeft {
    animation: slide-left 0.4s ease-in forwards;
  }
  
  .slideIn {
    transform: translateX(500px);
    opacity: 0;
    animation: slide-in 0.4s ease-out forwards;
  }
  
  @keyframes slide-left {
    100% {
      transform: translateX(-500px);
      opacity: 0;
    }
  }
  
  @keyframes slide-in {
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  