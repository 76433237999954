.root {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
}

.content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #888;
  padding: 5rem;
  border-radius: 5px;
  z-index: 10;
}

.underlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #0003;
  z-index: 5;
}

.closeBtn {
  color: red;
  position: absolute;
  right: 0.8rem;
  top: 0.3rem;
  font-weight: 600;
  font-size: 1.3rem;
  cursor: pointer;
}

.visible {
  animation: appear 0.3s ease forwards;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 500px) {
  .content {
    padding: 2rem;
  }
}
