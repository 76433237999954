.root {
  opacity: 0;
  animation: animate-in 0.8s ease forwards;
}

@keyframes animate-in {
  100% {
    opacity: 1;
  }
}
