.signOutBtn {
  position: absolute;
  font-size: 1rem;
  top: 0;
  left: 0;
  padding: 0.3rem 0.2rem;
  border-radius: 3px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #0000;
  margin: 0.3rem;
  font-weight: 500;
  transition: all 0.3s ease;
  border: 2px solid #0000;
  font-family: "Montserrat", sans-serif;
}

.signOutBtn:hover {
  box-shadow: 4px 4px 10px #0005;
  color: #000b;
}
