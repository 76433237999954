.field {
  display: flex;
  align-items: flex-end;
}

.label {
  font-size: 1.3rem;
  margin-right: 0.4rem;
}

.text-input {
  font-size: 1.3rem;
  color: var(--primary-font-color);
  border: none;
  outline: none;
  border-bottom: 1px solid #888;
}

.button {
  padding: 0.4rem 0.8rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 0.3rem;
  box-shadow: 1px 2px 2px #0003;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.button.primary {
  background-color: var(--primary-color);
  padding-left: 1.3rem;
  padding-right: 1.3rem;
  transition: all 0.3s ease;
}

.button:hover {
  transform: scale(1.06);
}

.button.google {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  border: 1px solid #0000;
  border-radius: 0.3rem;
  padding: 1rem;
  color: #555;
  box-shadow: 1px 2px 4px #0003;
}

.button.google:hover {
  transform: none;
}

.border-left {
  border-left: 1px solid var(--primary-font-color);
}

.border-top {
  border-top: 1px solid var(--primary-font-color);
}

.border-right {
  border-right: 1px solid var(--primary-font-color);
}

.border-bottom {
  border-bottom: 1px solid var(--primary-font-color);
}

.border {
  border: 1px solid var(--grey);
}

.rounded {
  border-radius: 0.3rem;
}

.rounded-full {
  border-radius: 50%;
}

.text-xl {
  font-size: 2.5rem;
}

.text-lg {
  font-size: 1.8rem;
}

.text-md {
  font-size: 1.2rem;
}

.text-sm {
  font-size: 0.8rem;
}

.text-micro {
  font-size: 0.6rem;
}

.text-center {
  text-align: center;
}

.width-full {
  width: 100%;
}

.height-full {
  height: 100%;
}

.width-screen {
  width: 100vw;
}

.height-screen {
  height: 100vh;
}

.width-005 {
  width: 5%;
}

.width-010 {
  width: 10%;
}

.width-015 {
  width: 15%;
}

.width-020 {
  width: 20%;
}

.width-025 {
  width: 25%;
}

.width-030 {
  width: 30%;
}

.width-035 {
  width: 35%;
}

.width-040 {
  width: 40%;
}

.width-045 {
  width: 45%;
}

.width-050 {
  width: 50%;
}

.width-055 {
  width: 55%;
}

.width-060 {
  width: 60%;
}

.width-065 {
  width: 65%;
}

.width-070 {
  width: 70%;
}

.width-075 {
  width: 75%;
}

.width-08 {
  width: 80%;
}

.width-085 {
  width: 85%;
}

.width-09 {
  width: 90%;
}

.width-095 {
  width: 95%;
}
.height-005 {
  height: 5%;
}

.height-010 {
  height: 10%;
}

.height-015 {
  height: 15%;
}

.height-020 {
  height: 20%;
}

.height-025 {
  height: 25%;
}

.height-030 {
  height: 30%;
}

.height-035 {
  height: 35%;
}

.height-040 {
  height: 40%;
}

.height-045 {
  height: 45%;
}

.height-050 {
  height: 50%;
}

.height-055 {
  height: 55%;
}

.height-060 {
  height: 60%;
}

.height-065 {
  height: 65%;
}

.height-070 {
  height: 70%;
}

.height-075 {
  height: 75%;
}

.height-08 {
  height: 80%;
}

.height-085 {
  height: 85%;
}

.height-09 {
  height: 90%;
}

.height-095 {
  height: 95%;
}

.max-w-xl {
  max-width: var(--screen-extra-large);
}

.max-w-lg {
  max-width: var(--screen-large);
}

.max-w-md {
  max-width: var(--screen-medium);
}

.max-w-sm {
  max-width: var(--screen-small);
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.min-h-screen {
  min-height: 100vh;
}

.mr-1 {
  margin-right: 0.1rem;
}

.mr-2 {
  margin-right: 0.2rem;
}

.mr-3 {
  margin-right: 0.3rem;
}

.mr-4 {
  margin-right: 0.4rem;
}
.mr-5 {
  margin-right: 0.5rem;
}
.mr-6 {
  margin-right: 0.6rem;
}
.mr-7 {
  margin-right: 0.7rem;
}
.mr-8 {
  margin-right: 0.8rem;
}
.mr-9 {
  margin-right: 0.9rem;
}
.mr-10 {
  margin-right: 1rem;
}
.mr-11 {
  margin-right: 1.1rem;
}
.mr-12 {
  margin-right: 1.2rem;
}
.mr-13 {
  margin-right: 1.3rem;
}
.mr-14 {
  margin-right: 1.4rem;
}
.mr-15 {
  margin-right: 1.5rem;
}
.mr-16 {
  margin-right: 1.6rem;
}
.mr-17 {
  margin-right: 1.7rem;
}
.mr-18 {
  margin-right: 1.8rem;
}
.mr-19 {
  margin-right: 1.9rem;
}

.mr-20 {
  margin-right: 2rem;
}
.mr-21 {
  margin-right: 2.1rem;
}
.mr-23 {
  margin-right: 2.3rem;
}
.mr-24 {
  margin-right: 2.4rem;
}
.mr-25 {
  margin-right: 2.5rem;
}
.mr-26 {
  margin-right: 2.6rem;
}
.mr-27 {
  margin-right: 2.7rem;
}
.mr-28 {
  margin-right: 2.8rem;
}
.mr-29 {
  margin-right: 2.9rem;
}
.mr-30 {
  margin-right: 3rem;
}

.ml-1 {
  margin-left: 0.1rem;
}

.ml-2 {
  margin-left: 0.2rem;
}

.ml-3 {
  margin-left: 0.3rem;
}

.ml-4 {
  margin-left: 0.4rem;
}
.ml-5 {
  margin-left: 0.5rem;
}
.ml-6 {
  margin-left: 0.6rem;
}
.ml-7 {
  margin-left: 0.7rem;
}
.ml-8 {
  margin-left: 0.8rem;
}
.ml-9 {
  margin-left: 0.9rem;
}
.ml-10 {
  margin-left: 1rem;
}
.ml-11 {
  margin-left: 1.1rem;
}
.ml-12 {
  margin-left: 1.2rem;
}
.ml-13 {
  margin-left: 1.3rem;
}
.ml-14 {
  margin-left: 1.4rem;
}
.ml-15 {
  margin-left: 1.5rem;
}
.ml-16 {
  margin-left: 1.6rem;
}
.ml-17 {
  margin-left: 1.7rem;
}
.ml-18 {
  margin-left: 1.8rem;
}
.ml-19 {
  margin-left: 1.9rem;
}

.ml-20 {
  margin-left: 2rem;
}
.ml-21 {
  margin-left: 2.1rem;
}
.ml-23 {
  margin-left: 2.3rem;
}
.ml-24 {
  margin-left: 2.4rem;
}
.ml-25 {
  margin-left: 2.5rem;
}
.ml-26 {
  margin-left: 2.6rem;
}
.ml-27 {
  margin-left: 2.7rem;
}
.ml-28 {
  margin-left: 2.8rem;
}
.ml-29 {
  margin-left: 2.9rem;
}

.ml-30 {
  margin-left: 3rem;
}

.mt-1 {
  margin-top: 0.1rem;
}

.mt-2 {
  margin-top: 0.2rem;
}

.mt-3 {
  margin-top: 0.3rem;
}

.mt-4 {
  margin-top: 0.4rem;
}
.mt-5 {
  margin-top: 0.5rem;
}
.mt-6 {
  margin-top: 0.6rem;
}
.mt-7 {
  margin-top: 0.7rem;
}
.mt-8 {
  margin-top: 0.8rem;
}
.mt-9 {
  margin-top: 0.9rem;
}
.mt-10 {
  margin-top: 1rem;
}
.mt-11 {
  margin-top: 1.1rem;
}
.mt-12 {
  margin-top: 1.2rem;
}
.mt-13 {
  margin-top: 1.3rem;
}
.mt-14 {
  margin-top: 1.4rem;
}
.mt-15 {
  margin-top: 1.5rem;
}
.mt-16 {
  margin-top: 1.6rem;
}
.mt-17 {
  margin-top: 1.7rem;
}
.mt-18 {
  margin-top: 1.8rem;
}
.mt-19 {
  margin-top: 1.9rem;
}

.mt-20 {
  margin-top: 2rem;
}
.mt-21 {
  margin-top: 2.1rem;
}
.mt-23 {
  margin-top: 2.3rem;
}
.mt-24 {
  margin-top: 2.4rem;
}
.mt-25 {
  margin-top: 2.5rem;
}
.mt-26 {
  margin-top: 2.6rem;
}
.mt-27 {
  margin-top: 2.7rem;
}
.mt-28 {
  margin-top: 2.8rem;
}
.mt-29 {
  margin-top: 2.9rem;
}

.mt-30 {
  margin-top: 3rem;
}

.mb-1 {
  margin-bottom: 0.1rem;
}

.mb-2 {
  margin-bottom: 0.2rem;
}

.mb-3 {
  margin-bottom: 0.3rem;
}

.mb-4 {
  margin-bottom: 0.4rem;
}
.mb-5 {
  margin-bottom: 0.5rem;
}
.mb-6 {
  margin-bottom: 0.6rem;
}
.mb-7 {
  margin-bottom: 0.7rem;
}
.mb-8 {
  margin-bottom: 0.8rem;
}
.mb-9 {
  margin-bottom: 0.9rem;
}
.mb-10 {
  margin-bottom: 1rem;
}
.mb-11 {
  margin-bottom: 1.1rem;
}
.mb-12 {
  margin-bottom: 1.2rem;
}
.mb-13 {
  margin-bottom: 1.3rem;
}
.mb-14 {
  margin-bottom: 1.4rem;
}
.mb-15 {
  margin-bottom: 1.5rem;
}
.mb-16 {
  margin-bottom: 1.6rem;
}
.mb-17 {
  margin-bottom: 1.7rem;
}
.mb-18 {
  margin-bottom: 1.8rem;
}
.mb-19 {
  margin-bottom: 1.9rem;
}

.mb-20 {
  margin-bottom: 2rem;
}
.mb-21 {
  margin-bottom: 2.1rem;
}
.mb-23 {
  margin-bottom: 2.3rem;
}
.mb-24 {
  margin-bottom: 2.4rem;
}
.mb-25 {
  margin-bottom: 2.5rem;
}
.mb-26 {
  margin-bottom: 2.6rem;
}
.mb-27 {
  margin-bottom: 2.7rem;
}
.mb-28 {
  margin-bottom: 2.8rem;
}
.mb-29 {
  margin-bottom: 2.9rem;
}

.mb-30 {
  margin-bottom: 3rem;
}

.pr-1 {
  margin-right: 0.1rem;
}

.pr-2 {
  margin-right: 0.2rem;
}

.pr-3 {
  margin-right: 0.3rem;
}

.pr-4 {
  margin-right: 0.4rem;
}
.pr-5 {
  margin-right: 0.5rem;
}
.pr-6 {
  margin-right: 0.6rem;
}
.pr-7 {
  margin-right: 0.7rem;
}
.pr-8 {
  margin-right: 0.8rem;
}
.pr-9 {
  margin-right: 0.9rem;
}
.pr-10 {
  margin-right: 1rem;
}
.pr-11 {
  margin-right: 1.1rem;
}
.pr-12 {
  margin-right: 1.2rem;
}
.pr-13 {
  margin-right: 1.3rem;
}
.pr-14 {
  margin-right: 1.4rem;
}
.pr-15 {
  margin-right: 1.5rem;
}
.pr-16 {
  margin-right: 1.6rem;
}
.pr-17 {
  margin-right: 1.7rem;
}
.pr-18 {
  margin-right: 1.8rem;
}
.pr-19 {
  margin-right: 1.9rem;
}

.pr-20 {
  margin-right: 2rem;
}
.pr-21 {
  margin-right: 2.1rem;
}
.pr-23 {
  margin-right: 2.3rem;
}
.pr-24 {
  margin-right: 2.4rem;
}
.pr-25 {
  margin-right: 2.5rem;
}
.pr-26 {
  margin-right: 2.6rem;
}
.pr-27 {
  margin-right: 2.7rem;
}
.pr-28 {
  margin-right: 2.8rem;
}
.pr-29 {
  margin-right: 2.9rem;
}
.pr-30 {
  margin-right: 3rem;
}

.pl-1 {
  margin-left: 0.1rem;
}

.pl-2 {
  margin-left: 0.2rem;
}

.pl-3 {
  margin-left: 0.3rem;
}

.pl-4 {
  margin-left: 0.4rem;
}
.pl-5 {
  margin-left: 0.5rem;
}
.pl-6 {
  margin-left: 0.6rem;
}
.pl-7 {
  margin-left: 0.7rem;
}
.pl-8 {
  margin-left: 0.8rem;
}
.pl-9 {
  margin-left: 0.9rem;
}
.pl-10 {
  margin-left: 1rem;
}
.pl-11 {
  margin-left: 1.1rem;
}
.pl-12 {
  margin-left: 1.2rem;
}
.pl-13 {
  margin-left: 1.3rem;
}
.pl-14 {
  margin-left: 1.4rem;
}
.pl-15 {
  margin-left: 1.5rem;
}
.pl-16 {
  margin-left: 1.6rem;
}
.pl-17 {
  margin-left: 1.7rem;
}
.pl-18 {
  margin-left: 1.8rem;
}
.pl-19 {
  margin-left: 1.9rem;
}

.pl-20 {
  margin-left: 2rem;
}
.pl-21 {
  margin-left: 2.1rem;
}
.pl-23 {
  margin-left: 2.3rem;
}
.pl-24 {
  margin-left: 2.4rem;
}
.pl-25 {
  margin-left: 2.5rem;
}
.pl-26 {
  margin-left: 2.6rem;
}
.pl-27 {
  margin-left: 2.7rem;
}
.pl-28 {
  margin-left: 2.8rem;
}
.pl-29 {
  margin-left: 2.9rem;
}

.pl-30 {
  margin-left: 3rem;
}

.pt-1 {
  margin-top: 0.1rem;
}

.pt-2 {
  margin-top: 0.2rem;
}

.pt-3 {
  margin-top: 0.3rem;
}

.pt-4 {
  margin-top: 0.4rem;
}
.pt-5 {
  margin-top: 0.5rem;
}
.pt-6 {
  margin-top: 0.6rem;
}
.pt-7 {
  margin-top: 0.7rem;
}
.pt-8 {
  margin-top: 0.8rem;
}
.pt-9 {
  margin-top: 0.9rem;
}
.pt-10 {
  margin-top: 1rem;
}
.pt-11 {
  margin-top: 1.1rem;
}
.pt-12 {
  margin-top: 1.2rem;
}
.pt-13 {
  margin-top: 1.3rem;
}
.pt-14 {
  margin-top: 1.4rem;
}
.pt-15 {
  margin-top: 1.5rem;
}
.pt-16 {
  margin-top: 1.6rem;
}
.pt-17 {
  margin-top: 1.7rem;
}
.pt-18 {
  margin-top: 1.8rem;
}
.pt-19 {
  margin-top: 1.9rem;
}

.pt-20 {
  margin-top: 2rem;
}
.pt-21 {
  margin-top: 2.1rem;
}
.pt-23 {
  margin-top: 2.3rem;
}
.pt-24 {
  margin-top: 2.4rem;
}
.pt-25 {
  margin-top: 2.5rem;
}
.pt-26 {
  margin-top: 2.6rem;
}
.pt-27 {
  margin-top: 2.7rem;
}
.pt-28 {
  margin-top: 2.8rem;
}
.pt-29 {
  margin-top: 2.9rem;
}

.pt-30 {
  margin-top: 3rem;
}

.pb-1 {
  margin-bottom: 0.1rem;
}

.pb-2 {
  margin-bottom: 0.2rem;
}

.pb-3 {
  margin-bottom: 0.3rem;
}

.pb-4 {
  margin-bottom: 0.4rem;
}
.pb-5 {
  margin-bottom: 0.5rem;
}
.pb-6 {
  margin-bottom: 0.6rem;
}
.pb-7 {
  margin-bottom: 0.7rem;
}
.pb-8 {
  margin-bottom: 0.8rem;
}
.pb-9 {
  margin-bottom: 0.9rem;
}
.pb-10 {
  margin-bottom: 1rem;
}
.pb-11 {
  margin-bottom: 1.1rem;
}
.pb-12 {
  margin-bottom: 1.2rem;
}
.pb-13 {
  margin-bottom: 1.3rem;
}
.pb-14 {
  margin-bottom: 1.4rem;
}
.pb-15 {
  margin-bottom: 1.5rem;
}
.pb-16 {
  margin-bottom: 1.6rem;
}
.pb-17 {
  margin-bottom: 1.7rem;
}
.pb-18 {
  margin-bottom: 1.8rem;
}
.pb-19 {
  margin-bottom: 1.9rem;
}

.pb-20 {
  margin-bottom: 2rem;
}
.pb-21 {
  margin-bottom: 2.1rem;
}
.pb-23 {
  margin-bottom: 2.3rem;
}
.pb-24 {
  margin-bottom: 2.4rem;
}
.pb-25 {
  margin-bottom: 2.5rem;
}
.pb-26 {
  margin-bottom: 2.6rem;
}
.pb-27 {
  margin-bottom: 2.7rem;
}
.pb-28 {
  margin-bottom: 2.8rem;
}
.pb-29 {
  margin-bottom: 2.9rem;
}

.pb-30 {
  margin-bottom: 3rem;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.items-base {
  align-items: baseline;
}

.justify-center {
  justify-content: center;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.cursor-pointer {
  cursor: pointer;
}

@media screen and (max-width: 500px) {
    .field {
      flex-direction: column;
      align-items: flex-start;
    }

    .label {
      margin-bottom: .4rem;
      font-size: 1rem;
    }
}