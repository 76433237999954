.contentWrapper {
  text-align: center;
  padding: 1rem;
  color: var(--font-color);
  width: fit-content;
  margin: auto;
}

.input {
  margin-bottom: 50px;
}

.form {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  width: 100%;
  height: 80%;
}

.submitButton:hover {
  transform: scale(1);
}

.drop {
  height: 300px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 4px solid #444;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.089);
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.3s ease;
}
.accept {
  border-color: rgb(32, 196, 59);
  color: rgb(32, 196, 59);
}
.reject {
  border-color: rgb(196, 32, 32);
  color: rgb(196, 32, 32);
}

.drop:hover {
  background-color: #ddd;
}

.smallTip {
  font-size: 0.6rem;
  color: #888;
}
