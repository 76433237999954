.subtitle {
  margin-bottom: 5rem;
}

.button {
  background-color: #0000;
  color: var(--font-color);
  border: 2px solid #bbb;
  font-size: 1.5rem;
  border-radius: 10px;
  padding: 0.8rem 1.6rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button:hover {
  transform: scale(1.05);
  background-color: #eeeeee;
  color: var(--font-color);
  border-color: #888;
  box-shadow: 10px 10px 8px #0002;
}

@media screen and (max-width: 500px) {
  .subtitle {
    margin-right: .3rem;
    margin-left: .3rem;
  }
}
