:root {
  --background-color: #f5f5f5;
  --font-color: #333;
  --primary-color: #24baff;

  --screen-extra-large: 1200px;
  --screen-large: 1000px;
  --screen-medium: 800px;
  --screen-small: 600px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: calc(10px + 2vmin);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-primary {
  color: var(--primary-color);
}

.primary {
  background: var(--primary-color);
}

a {
  text-decoration: none;
}
