@keyframes primary-button-hover {
  100% {
    box-shadow: 3px 5px 5px #0003;
  }
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
